<script>
export default {
  metaInfo: {
    title: "General Settings",
  }
};
</script>

<script setup>
import PhpServiceView from '@/components/basic/php-wrappers/PhpServiceView.vue';
import OrgSettingsControl from '@/components/dynamic/orgSettingsControl.vue';
</script>

<template>
  <PhpServiceView url="?f=administration&f2=organisationGeneralSettings" #default="{result}">
    <OrgSettingsControl :result="result"/>
  </PhpServiceView>
</template>

<style scoped>

</style>